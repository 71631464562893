import { Container, Flex } from '@radix-ui/themes';

const Sponsers = () => {
  return (
    <Container size='4' align='center'>
      <Flex
        direction='column'
        align='center'
        gap={{ initial: '7', md: '9' }}
        className='text-center'
      >
        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <h1 className='text-2xl md:text-3xl font-bold text-center'>
            SPONSERS
          </h1>

          <p className='text-[#008000] text-base md:text-lg font-semibold text-center'>
            On behalf of Jashn 2024- The Mystic Saga, we extend our sincerest
            gratitude for your invaluable support and contribution to our event.
            <br />
            As a sponsor, you have not only demonstrated your commitment to
            fostering cultural appreciation but also helped us create memorable
            experiences for our audience and participants. We deeply appreciate
            your partnership and look forward to continuing this journey
            together. Thank you once again for your unwavering dedication and
            generosity.
          </p>
        </Flex>

        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <h1 className='text-2xl md:text-3xl font-bold text-center'>
            PARTNERS
          </h1>

          <Flex
            width='100%'
            direction='row'
            align='center'
            className='justify-evenly'
          >
            <Flex
              width='100%'
              direction='column'
              align='center'
              gap={{ initial: '3', md: '5' }}
            >
              <img
                src='/assets/sponsors/desco.png'
                alt='DESCO'
                className='h-[50px] md:h-[80px]'
              />

              <h1 className='text-xl md:text-2xl font-bold text-center'>
                PRINT
              </h1>
            </Flex>

            <Flex
              width='100%'
              direction='column'
              align='center'
              gap={{ initial: '3', md: '5' }}
            >
              <img
                src='/assets/sponsors/radiomirchi.png'
                alt='Radio Mirchi'
                className='h-[50px] md:h-[80px]'
              />

              <h1 className='text-xl md:text-2xl font-bold text-center'>
                RADIO
              </h1>
            </Flex>

            <Flex
              width='100%'
              direction='column'
              align='center'
              gap={{ initial: '3', md: '5' }}
            >
              <img
                src='/assets/sponsors/zee.png'
                alt='ZEE'
                className='h-[50px] md:h-[80px]'
              />

              <h1 className='text-xl md:text-2xl font-bold text-center'>
                TELEVISION
              </h1>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <h1 className='text-2xl md:text-3xl font-bold text-center'>SILVER</h1>

          <Flex
            width='100%'
            direction='row'
            align='center'
            className='justify-evenly flex-wrap'
            gap={{ initial: '5', md: '8' }}
          >
            <img
              src='/assets/sponsors/zaad.png'
              alt='Zaad'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/bydi.png'
              alt='Bin Yaber'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/sony.png'
              alt='Sony'
              className='h-[40px] md:h-[70px]'
            />
          </Flex>
        </Flex>

        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <h1 className='text-2xl md:text-3xl font-bold text-center'>BRONZE</h1>

          <Flex width='100%' direction='row' align='center' justify='center'>
            <img
              src='/assets/sponsors/klik.svg'
              alt='Klik'
              className='h-[50px] md:h-[80px]'
            />
          </Flex>
        </Flex>
        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <h1 className='text-2xl md:text-3xl font-bold text-center'>
            ASSOCIATE
          </h1>

          <Flex
            width='100%'
            direction='row'
            align='center'
            className='justify-evenly'
          >
            <img
              src='/assets/sponsors/circles.png'
              alt='Circles'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/enchanteur.png'
              alt='Enchanteur'
              className='h-[50px] md:h-[80px]'
            />
          </Flex>
        </Flex>

        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <h1 className='text-2xl md:text-3xl font-bold text-center'>
            AND MORE
          </h1>

          <Flex
            width='100%'
            direction='row'
            align='center'
            gap={{ initial: '5', md: '8' }}
            className='justify-evenly flex-wrap'
          >
            <img
              src='/assets/sponsors/chachachai.jpg'
              alt='Cha Cha Chai'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/snowietime.png'
              alt='Snowie Time'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/caramalise.png'
              alt='Caramalise'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/chocofondue.png'
              alt='Choco Fondue'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/apnakabab.png'
              alt='Apna Kabab'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/hangryjoes.png'
              alt='Hangry Joes'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/kitty.png'
              alt='Kitty'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/pizzanini.png'
              alt='Pizza Nini'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/asianroots.jpg'
              alt='Asian Roots'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/lunchbox.png'
              alt='Lunch Box'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/wedesi.png'
              alt='We Desi'
              className='h-[50px] md:h-[80px]'
            />
            <img
              src='/assets/sponsors/shawarma.png'
              alt='Shawarma'
              className='h-[50px] md:h-[80px]'
            />
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Sponsers;
