import { Menu } from 'lucide-react';
import { Flex } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { Box, Drawer, ModalClose } from '@mui/joy';

const MobileNavBar = ({ backgroundColor, menuColor, textColor }) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Menu color={menuColor} onClick={() => setOpen(true)} />
      <Drawer open={open} anchor='right' onClose={() => setOpen(false)}>
        <Box className={`h-dvh ${backgroundColor}`}>
          <Box className='w-full flex flex-row items-center justify-end p-4'>
            <ModalClose sx={{ position: 'initial' }} />
          </Box>

          <Flex
            direction='column'
            align='center'
            className='text-xl font-semibold p-4 gap-2'
          >
            <Link
              to='/'
              onClick={() => setOpen(false)}
              className={`bg-[#fdfdfd] ${textColor} py-4 w-full rounded-xl text-center`}
            >
              Home
            </Link>

            <Link
              to='/information'
              onClick={() => setOpen(false)}
              className={`bg-[#fdfdfd] ${textColor} py-4 w-full rounded-xl text-center`}
            >
              Information
            </Link>

            <Link
              to='/events'
              onClick={() => setOpen(false)}
              className={`bg-[#fdfdfd] ${textColor} py-4 w-full rounded-xl text-center`}
            >
              Calendar
            </Link>
            {/* Disabled Jashn over  */}
            {/* <Link
              to='/register'
              onClick={() => setOpen(false)}
              className={`bg-[#fdfdfd] ${textColor} py-4 w-full rounded-xl text-center`}
            >
              Register
            </Link> */}

            <Link
              to='/sponsers'
              onClick={() => setOpen(false)}
              className={`bg-[#fdfdfd] ${textColor} py-4 w-full rounded-xl text-center`}
            >
              Supporters
            </Link>
            <Link
              onClick={() => setOpen(false)}
              className={`bg-[#fdfdfd] ${textColor} py-4 w-full rounded-xl text-center`}
              to={'/photos'}
            >
              Photos
            </Link>
          </Flex>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default MobileNavBar;
