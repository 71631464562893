import { Container, Flex, Button } from '@radix-ui/themes';

const Information = () => {
  return (
    <Container size='4' align='center'>
      <Flex
        direction='column'
        align='center'
        gap={{ initial: '7', md: '9' }}
        className='text-center'
      >
        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <h1 className='text-2xl md:text-3xl font-bold text-center'>
            INFORMATION
          </h1>

          {/* TODO change coloring on this page */}
          <p className='text-[#FF3300] text-base md:text-lg font-semibold text-center'>
            BITS Pilani Dubai Campus gears up to host the largest
            inter-university and now, inter-school cultural festival in all of
            UAE.
            <br />
            <br />
            This year, April 24th to 27th 2024, will witness students from 15
            Universities and 12 Schools from all over the country, battle it out
            and showcase their diverse talents in blockbuster events like Battle
            of Bands, Solo, Duet and Group Dance Battles, Stand-Up Comedy,
            Theatre, Photography, Solo and Duet Singing, Improv Comedy and much
            more!
            <br />
            <br />
            Jashn is a celebration of talent, hard-work and spirit, with the
            firm belief that excellence is not an act, but a habit.
            <br />
            <br />
            The finale of this megafest will bring to the glorious campus a
            popular artist, potentially DJ Krsna, Foosie Gang, Ravator and
            Trapperex. So get ready to turn up the volume, dance, jump, and lose
            yourselves in the music because it's going to be an unforgettable
            night!
            <br />
            <br />
            Join us at the one-stop-shop for dance, music, drama, fashion and a
            whole lot of fun, Jashn 2024 - The Mystic Saga!
          </p>
        </Flex>

        <Flex
          width='100%'
          direction='column'
          align='center'
          gap={{ initial: '3', md: '5' }}
        >
          <h1 className='text-2xl md:text-3xl font-bold text-center'>
            RULE BOOKS
          </h1>

          <Flex direction='row' align='center' gap='5'>
            <Button
              variant='solid'
              className='bg-[#B6554F] py-5 px-9 rounded-lg cursor-pointer text-lg md:text-xl font-bold'
              onClick={() => {
                window.open('/assets/rulebooks/university.pdf', '_blank');
              }}
            >
              UNIVERSITY
            </Button>
            <Button
              variant='solid'
              className='bg-[#B6554F] py-5 px-9 rounded-lg cursor-pointer text-lg md:text-xl font-bold'
              onClick={() => {
                window.open('/assets/rulebooks/school.pdf', '_blank');
              }}
            >
              SCHOOL
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Information;
