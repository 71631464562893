import React from 'react';
import { Flex } from '@radix-ui/themes';
import { Link } from 'react-router-dom';

const NavBar = () => {
  return (
    <React.Fragment>
      <Flex
        direction='row'
        align='center'
        justify='between'
        gap={{ initial: '5', md: '9' }}
        className='bg-[#fdfdfd] rounded-full py-4 px-10 lg:px-16 text-sm lg:text-lg font-bold w-full md:w-auto'
      >
        <Link
          className='hover:scale-105 md:hover:scale-110 transition-all duration-200 ease-in navbar-links'
          to='/'
        >
          Home
        </Link>

        <Link
          className='hover:scale-105 md:hover:scale-110 transition-all duration-200 ease-in navbar-links'
          to='/information'
        >
          Information
        </Link>

        <Link
          className='hover:scale-105 md:hover:scale-110 transition-all duration-200 ease-in navbar-links'
          to='/events'
        >
          Calendar
        </Link>

        {/* Disabled Jashn over */}
        {/* <Link
          className='hover:scale-105 md:hover:scale-110 transition-all duration-200 ease-in navbar-links'
          to='/register'
        >
          Register
        </Link> */}

        <Link
          className='hover:scale-105 md:hover:scale-110 transition-all duration-200 ease-in navbar-links'
          to='/sponsers'
        >
          Supporters
        </Link>
        <Link
          className='hover:scale-105 md:hover:scale-110 transition-all duration-200 ease-in navbar-links'
          to={'/photos'}
        >
          Photos
        </Link>
      </Flex>
    </React.Fragment>
  );
};

export default NavBar;
