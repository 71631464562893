import { Flex } from '@radix-ui/themes';

import Card from './Card';

const Day = ({ date, events }) => {
  return (
    <Flex
      width='100%'
      direction='column'
      align='center'
      gap={{ initial: '2', md: '4' }}
    >
      <h1 className='text-2xl md:text-3xl font-bold text-center'>{date}</h1>

      <div
        className='flex flex-row items-center justify-start w-full scroll-smooth overflow-x-scroll'
        style={{
          scrollbarWidth: 'auto',
          scrollBehavior: 'smooth',
          scrollbarColor: '#B6554F #00000000',
        }}
      >
        <Flex
          direction='row'
          align='center'
          justify='start'
          className='w-max mb-2'
        >
          {events.map((event) => {
            let imageDownloadUrl = '';
            if (event.image) {
              imageDownloadUrl = event.image[0].downloadURL;
            } else {
              imageDownloadUrl =
                'https://jashn.bitsdubaievents.com/assets/logos/jashn-logo.png';
            }
            return (
              event && (
                <Card
                  key={imageDownloadUrl}
                  imgUrl={imageDownloadUrl}
                  description={event?.description ?? ''}
                  title={event?.name}
                  venue={event?.venue}
                  time={new Date(event.dateAndTime * 1000).toLocaleTimeString(
                    [],
                    {
                      hour: '2-digit',
                      minute: '2-digit',
                    }
                  )}
                />
              )
            );
          })}
        </Flex>
      </div>
    </Flex>
  );
};

export default Day;
