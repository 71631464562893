import { logEvent } from 'firebase/analytics';
import { useLocation } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';

import NavBar from './NavBar';
import Footer from './Footer';
import Loading from './Loading';
import { Toaster } from './Toaster';
import MobileNavBar from './MobileNavBar';
import { analytics } from '../services/firebase';

function Layout({ children }) {
  logEvent(analytics, 'website_start');
  const location = useLocation();
  const [menuColor, setMenuColor] = useState('#00215A');
  const [textColor, setTextColor] = useState('text-[#00215A]');
  const [backgroundColor, setBackgroundColor] = useState('bg-[#DFEBFF]');

  useEffect(() => {
    const updateBackgroundColor = () => {
      switch (location.pathname) {
        case '/':
          setMenuColor('#DFEBFF');
          setTextColor('text-[#00215A]');
          setBackgroundColor('bg-[#DFEBFF]');
          break;
        case '/information':
          setMenuColor('#FF3300'); // TODO: Change this to the actual color for information page
          setTextColor('text-[#FF3300]'); // TODO: Change this to the actual color for information page
          setBackgroundColor('bg-[#FFDAB9]'); // TODO: Change this to the actual color for information page
          break;
        case '/events':
          setMenuColor('#CD5C5C');
          setTextColor('text-[#CD5C5C]');
          setBackgroundColor('bg-[#FFD8D8]');
          break;
        case '/register':
          setMenuColor('#6C3483');
          setTextColor('text-[#6C3483]');
          setBackgroundColor('bg-[#E8DAEF]');
          break;
        case '/sponsers':
          setMenuColor('#008000');
          setTextColor('text-[#008000]');
          setBackgroundColor('bg-[#B2E3B2]');
          break;
        default:
          setBackgroundColor('');
      }
    };

    updateBackgroundColor();
  }, [location]);

  return (
    <React.Fragment>
      <main
        className={`min-h-dvh w-screen flex flex-col justify-between gap-5 md:gap-10 ${backgroundColor}`}
      >
        {/* TODO add BITS logo to the right end here */}
        <img
          alt='logo'
          src='/assets/logos/jashn-logo.png'
          className='absolute z-10 left-3 md:left-5 top-3 md:top-3 h-auto w-[50px] md:w-[80px]'
        />

        <img
          alt='logo'
          src='/assets/logos/BITSLogo.png'
          className='absolute z-10 right-3 md:right-5 top-3 md:top-8 h-auto w-[120px] md:w-[150px] hidden md:block'
        />

        <div className='absolute z-10 top-7 w-full flex-row items-center justify-center hidden md:flex'>
          <NavBar />
        </div>

        <div className='absolute z-10 top-5 right-5 block md:hidden'>
          <MobileNavBar
            menuColor={menuColor}
            textColor={textColor}
            backgroundColor={backgroundColor}
          />
        </div>

        <div className='pt-20 md:pt-32 px-5 md:px-0'>
          <Suspense fallback={<Loading />}>{children}</Suspense>

          <Toaster />
        </div>

        <Footer textColor={textColor} />
      </main>
    </React.Fragment>
  );
}

export default Layout;
