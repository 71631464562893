import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Events from './pages/Events';
import Sponsers from './pages/Sponsers';
import Layout from './components/Layout';
import Loading from './components/Loading';
import Information from './pages/Information';
import Photos from './pages/Photos';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <Suspense fallback={<Loading />}>
                <Layout>
                  <Home />
                </Layout>
              </Suspense>
            }
          />
          <Route
            path='/information'
            element={
              <Suspense fallback={<Loading />}>
                <Layout>
                  <Information />
                </Layout>
              </Suspense>
            }
          />
          <Route
            path='/events'
            element={
              <Suspense fallback={<Loading />}>
                <Layout>
                  <Events />
                </Layout>
              </Suspense>
            }
          />
          {/* Jashn over disabled */}
          {/* <Route
            path="/register"
            element={
              <Suspense fallback={<Loading />}>
                <Layout>
                  <Register />
                </Layout>
              </Suspense>
            }
          /> */}
          <Route
            path='/sponsers'
            element={
              <Suspense fallback={<Loading />}>
                <Layout>
                  <Sponsers />
                </Layout>
              </Suspense>
            }
          />
          <Route path='/photos' element={<Photos />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
