import { Container, Flex, Tabs } from '@radix-ui/themes';
import React, { Suspense, useEffect, useState } from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';

import Day from '../components/Day';
import Loading from '../components/Loading';
import { firestore } from '../services/firebase';

function Events() {
  const [universityEvents, setUniversityEvents] = useState([]);
  const [schoolEvents, setSchoolEvents] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchData = () => {
      try {
        const eventsCollection = collection(
          firestore,
          `subevents/${currentYear}/jashn`
        );

        const unsubscribeEvents = onSnapshot(
          query(eventsCollection),
          async (snapshot) => {
            const updatedEvents = await Promise.all(
              snapshot.docs.map(async (doc) => {
                const data = doc.data();
                return data;
              })
            );
            updatedEvents.sort((event1, event2) => {
              const timestamp1 = event1.dateAndTime.seconds;
              const timestamp2 = event2.dateAndTime.seconds;

              if (timestamp1 < timestamp2) {
                return -1;
              } else if (timestamp1 > timestamp2) {
                return 1;
              } else {
                return 0;
              }
            });

            const universityEventsData = updatedEvents.filter(
              (event) => event.type === 'university'
            );
            const schoolEventsData = updatedEvents.filter(
              (event) => event.type === 'school'
            );

            setUniversityEvents(universityEventsData);
            setSchoolEvents(schoolEventsData);
          }
        );
        return () => {
          unsubscribeEvents();
        };
      } catch (error) {
        console.error('Error fetching events : ', error);
      }
    };
    fetchData();
  }, [currentYear]);

  return (
    <Container size='4' align='center'>
      <Tabs.Root
        defaultValue='dashboard'
        className='flex flex-col items-center w-full h-full gap-5 md:gap-9 mb-5 md:mb-9'
      >
        <Tabs.List className='text-lg md:text-xl font-bold'>
          <Tabs.Trigger value='dashboard'>UNIVERSITY</Tabs.Trigger>
          <Tabs.Trigger value='scanner'>SCHOOL</Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value='dashboard' className='w-full h-full'>
          <Flex
            direction='column'
            align='center'
            gap={{ initial: '4', md: '6' }}
            className='text-center'
          >
            <Suspense fallback={<Loading />}>
              {universityEvents && universityEvents.length !== 0 && (
                <Day
                  date='24TH APRIL, 2024'
                  events={universityEvents.filter((event) => {
                    const eventDate = new Date(event.dateAndTime * 1000);
                    return (
                      eventDate.getDate() === 24 && eventDate.getMonth() === 3
                    );
                  })}
                />
              )}
            </Suspense>
            <Suspense fallback={<Loading />}>
              {universityEvents && universityEvents.length !== 0 && (
                <Day
                  date='25TH APRIL, 2024'
                  events={universityEvents.filter((event) => {
                    const eventDate = new Date(event.dateAndTime * 1000);
                    return (
                      eventDate.getDate() === 25 && eventDate.getMonth() === 3
                    );
                  })}
                />
              )}
            </Suspense>
            <Suspense fallback={<Loading />}>
              {universityEvents && universityEvents.length !== 0 && (
                <Day
                  date='26TH APRIL, 2024'
                  events={universityEvents.filter((event) => {
                    const eventDate = new Date(event.dateAndTime * 1000);
                    return (
                      eventDate.getDate() === 26 && eventDate.getMonth() === 3
                    );
                  })}
                />
              )}
            </Suspense>
            <Suspense fallback={<Loading />}>
              {universityEvents && universityEvents.length !== 0 && (
                <Day
                  date='27TH APRIL, 2024'
                  events={universityEvents.filter((event) => {
                    const eventDate = new Date(event.dateAndTime * 1000);
                    return (
                      eventDate.getDate() === 27 && eventDate.getMonth() === 3
                    );
                  })}
                />
              )}
            </Suspense>
          </Flex>
        </Tabs.Content>

        <Tabs.Content value='scanner' className='w-full h-full'>
          <Flex
            direction='column'
            align='center'
            gap={{ initial: '4', md: '6' }}
            className='text-center'
          >
            <Suspense fallback={<Loading />}>
              {schoolEvents && schoolEvents.length !== 0 && (
                <Day
                  date='24TH APRIL, 2024'
                  events={schoolEvents.filter((event) => {
                    const eventDate = new Date(event.dateAndTime * 1000);
                    return (
                      eventDate.getDate() === 24 && eventDate.getMonth() === 3
                    );
                  })}
                />
              )}
            </Suspense>
            <Suspense fallback={<Loading />}>
              {schoolEvents && schoolEvents.length !== 0 && (
                <Day
                  date='25TH APRIL, 2024'
                  events={schoolEvents.filter((event) => {
                    const eventDate = new Date(event.dateAndTime * 1000);
                    return (
                      eventDate.getDate() === 25 && eventDate.getMonth() === 3
                    );
                  })}
                />
              )}
            </Suspense>
            <Suspense fallback={<Loading />}>
              {schoolEvents && schoolEvents.length !== 0 && (
                <Day
                  date='26TH APRIL, 2024'
                  events={schoolEvents.filter((event) => {
                    const eventDate = new Date(event.dateAndTime * 1000);
                    return (
                      eventDate.getDate() === 26 && eventDate.getMonth() === 3
                    );
                  })}
                />
              )}
            </Suspense>
            <Suspense fallback={<Loading />}>
              {schoolEvents && schoolEvents.length !== 0 && (
                <Day
                  date='27TH APRIL, 2024'
                  events={schoolEvents.filter((event) => {
                    const eventDate = new Date(event.dateAndTime * 1000);
                    return (
                      eventDate.getDate() === 27 && eventDate.getMonth() === 3
                    );
                  })}
                />
              )}
            </Suspense>
          </Flex>
        </Tabs.Content>
      </Tabs.Root>
    </Container>
  );
}

export default Events;
