import React from 'react';
import { Box, Flex, Grid, AlertDialog, Button } from '@radix-ui/themes';

const Card = ({ imgUrl, title, time, description, venue }) => {
  return (
    <Box p={{ initial: '2', md: '4' }} width='100%' height='100%'>
      <AlertDialog.Root>
        <AlertDialog.Trigger>
          <Grid
            p={{ initial: '3', xs: '4' }}
            className='h-[170px] md:h-[250px] w-[170px] md:w-[250px] rounded-3xl hover:scale-105 md:hover:scale-110 transition-all duration-200 ease-in cursor-pointer'
            align='end'
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(${imgUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Flex
              align='start'
              justify='end'
              direction='column'
              className='font-semibold md:font-bold text-base md:text-xl text-[#fdfdfd] z-5 text-left text-balance gap-2'
            >
              <p className='uppercase text-xl md:text-2xl font-bold'>{title}</p>
              <p className='uppercase text-xl md:text-2xl font-bold'>{time}</p>
            </Flex>
          </Grid>
        </AlertDialog.Trigger>
        <AlertDialog.Content className='max-w-[350px] md:max-w-[500px] flex flex-col items-center gap-5 p-10 md:p-12'>
          <AlertDialog.Title className='text-xl md:text-2xl text-center'>
            {title}
          </AlertDialog.Title>

          <AlertDialog.Description className='flex flex-col items-center gap-5 text-sm md:text-base text-balance text-center'>
            <p>
              Venue: <strong>{venue}</strong>
            </p>
            <p>
              Time: <strong>{time}</strong>
            </p>
            {description}
          </AlertDialog.Description>

          <AlertDialog.Cancel>
            <Button
              variant='solid'
              className='bg-[#B6554F] px-9 rounded-lg cursor-pointer'
            >
              Okay
            </Button>
          </AlertDialog.Cancel>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </Box>
  );
};

export default Card;
