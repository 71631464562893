import React from 'react';
import { Container, Grid } from '@radix-ui/themes';

const Home = () => {
  return (
    <React.Fragment>
      <Grid
        columns={{ initial: '1', md: '3' }}
        rows={{ initial: '3', md: '1' }}
        align='center'
        className='h-dvh -mt-20 md:-mt-32 -ml-5 md:-ml-0 -mr-5 md:-mr-0'
      >
        <img
          alt='bg2'
          src='/assets/landing/bg1.jpeg'
          className='object-cover bg-center h-full w-full brightness-50'
          loading='eager'
        />

        <img
          alt='bg1'
          src='/assets/landing/bg2.png'
          className='object-cover bg-center h-full w-full brightness-50'
          loading='eager'
        />

        <img
          alt='bg3'
          loading='eager'
          src='/assets/landing/bg3.jpeg'
          className='object-cover bg-center h-full w-full brightness-50'
        />

        <Grid
          align='center'
          className='absolute w-full h-dvh'
          columns={{ initial: '1', md: '3' }}
        >
          <h1 className='text-6xl md:text-8xl font-bold text-[#fdfdfd] text-center'>
            JASHN
          </h1>

          <h1 className='text-6xl md:text-8xl font-bold text-[#fdfdfd] text-center'>
            YOUR
          </h1>

          <h1 className='text-6xl md:text-8xl font-bold text-[#fdfdfd] text-center'>
            DREAMS
          </h1>
        </Grid>
      </Grid>

      <Container size='4' align='center' className='mt-5 md:mt-10'>
        <Grid
          columns={{ initial: '1', md: '2' }}
          align='center'
          gap={{ initial: '4', md: '8' }}
        >
          <div className='h-[200px] md:h-[300px] w-full bg-gray-500 rounded-3xl'>
            <iframe
              title='BPDC Map'
              style={{ border: '0', borderRadius: '10px' }}
              loading='eager'
              allowFullScreen
              src='https://www.google.com/maps/embed/v1/place?q=place_id:ChIJg3YLr-xjXz4Rxzv2jtsDiRc&key=AIzaSyAeQn41vC5LzF_Wote1l6zB4DiCuvjrZ9s'
              className='h-full w-full rounded-3xl'
            ></iframe>
          </div>

          <p className='text-[#00215A] text-2xl md:text-4xl font-bold text-center md:text-left'>
            Jashn is excitement, emoción, excitation, Aufregung, eccitazione,
            empolgação, волнение, 兴奋, 興奮, 흥분, إثارة, उत्साह, heyecan,
            opwinding, spänning!
          </p>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Home;
