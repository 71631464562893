import { useEffect } from 'react';
import Loading from '../components/Loading';

const Photos = () => {
  useEffect(() => {
    window.location.href =
      'https://drive.google.com/drive/folders/13bbjzah25Cp_1l-K3Wsx7NXrxr52-AW3?usp=sharing';
  }, []);

  return <Loading />;
};

export default Photos;
